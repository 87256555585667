import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <iframe className="figma-container" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fl23MCLnCw1mdF65HA2qu1r%2FUnderscore%3Fnode-id%3D10%253A267%26scaling%3Dmin-zoom" allowfullscreen />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
